<template lang="pug">
div(v-if='Blog')
  //-PC
  div.d-none.d-md-flex
    v-container(fluid).pa-0.ma-0
      v-row
        v-divider.negro.mt-6.mb-7
      v-row.ml-3
          h3.grey--text.lighten-2 Palabras claves
      v-row.mr-4.ml-3
        v-slide-group.mt-2
            v-slide-item(v-for='{nombre, orden, id}, i in Registro_de_etiquetas' :key='i' 
            v-slot:default='{ active, toggle }' )
                v-chip.white--text(:color='id == Etiqueta_filtro ? "primario" : "#CCCCCC"' 
                elevation='0' @click='(Etiqueta_filtro==id) ? alterar_etiqueta("") : alterar_etiqueta(id) ').chip.mr-4
                    span.black--text.pr-3.pl-3 {{nombre}}
        v-spacer
        v-text-field(placeholder='Buscar' v-model='texto_buscador' color='#999999' outlined append-icon='fas fa-search').mr-2
      v-row.mt-8.pr-6.pl-6(v-if='registro_de_entradas.length>0')
        Novedad_item(v-for='entrada, i in registro_de_entradas' :key='i' :entrada='entrada').pl-12.pr-12
      v-row(v-if='registro_de_entradas.length>0').mt-12.pr-6.pl-6.ml-12.mr-1.mb-12
        v-container.align-center.justify-center
          v-pagination(v-model="página" color='negro' :length="1" prev-icon="mdi-menu-left" next-icon="mdi-menu-right")
      v-row.mt-8.pr-6.pl-6(v-else)
        v-container(fluid align='center' justify='center')
          v-row(align='center' justify='center').py-5
              v-icon(x-large ) fas fa-exclamation-triangle
          v-row(align='center' justify='center')
            h2.piqr--text ¡Ups! No se ha encontrado la entrada.
          v-row(align='center' justify='center')
            strong Verifique su escritura o inténtelo de nuevo.
      v-row
        v-divider.negro.mt-6.mb-7
  v-container.d-md-none
    v-row
      v-divider.negro.mt-6.mb-7
    v-row.ml-3
        h3.grey--text.lighten-2.font-weight-light Palabras claves
    v-row.mr-4.ml-3.mb-5
      v-slide-group.mt-2
          v-slide-item(v-for='{nombre, orden, id}, i in Registro_de_etiquetas' :key='i' 
          v-slot:default='{ active, toggle }')
              v-chip.white--text(:color='id == Etiqueta_filtro ? "primario" : "#CCCCCC"' 
                elevation='0' @click='(Etiqueta_filtro==id) ? alterar_etiqueta("") : alterar_etiqueta(id) ').chip.mr-4
                    span.black--text.pr-3.pl-3 {{nombre}}
    v-row(align='center' justify='center').mt-6.pl-4.pr-4
      v-text-field(placeholder='Buscar' v-model='texto_buscador' color='#999999' outlined append-icon='fas fa-search')
    v-row.mt-1(v-if='registro_de_entradas.length>0')
      Novedad_item(v-for='entrada, i in registro_de_entradas' :key='i' :entrada='entrada')
    v-row(v-if='registro_de_entradas.length>0').mt-5.pr-6.pl-6.ml-12.mr-1.mb-12.align-center.justify-center
      v-pagination(v-model="página" color='negro' :length="1" prev-icon="mdi-menu-left" next-icon="mdi-menu-right")
    v-row.mt-1.pr-6.pl-6(v-else)
      v-container(fluid align='center' justify='center')
        v-row(align='center' justify='center').py-5
            v-icon(x-large ) fas fa-exclamation-triangle
        v-row(align='center' justify='center')
          h2.piqr--text.text-center ¡Ups! No se ha encontrado la entrada.
        v-row(align='center' justify='center')
          strong Verifique su escritura o inténtelo de nuevo.
    v-row
      v-divider.negro.mt-6.mb-7
</template>
<script>
import { mapMutations, mapState } from 'vuex'
export default {
    data: ()=>({
        página: 1,
        texto_buscador: '',
    }),
    computed: {
      registro_de_entradas(){
        let arreglo_principal = this.$store.getters['Blog/registro_de_entradas_por_etiquetas'](this.Etiqueta_filtro)
        let arreglo_filtrado = []
        if(this.texto_buscador==""){
          arreglo_filtrado = arreglo_principal
        } else {
          arreglo_filtrado = arreglo_principal.filter(({título})=>título.toUpperCase().includes(this.texto_buscador.toUpperCase()))
        }
        return arreglo_filtrado
      },
      ...mapState({
        Etiqueta_filtro: ({Etiquetas})=>Etiquetas.etiqueta_filtro,
      }),
    },
    components: {
        Novedad_item: ()=>import('./novedad_item'),
    },
    methods: {
      ...mapMutations({
        alterar_etiqueta: 'Etiquetas/alterar_etiqueta',
      }),
    },
}
</script>